/* You can add global styles to this file, and also import other style files */
table {
  width: 100%;
}

.table-container-size {
  position: relative;
  min-height: 200px;
  max-height: 550px;
  overflow: auto;
}

.text-title-green {
  color: #7ae500 !important;
}

.text-title {
  font-size: 2.3em;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.text-title-card {
  font-size: 1.6em !important;
}

.text-total {
  padding: 2px;
  font-size: 4em !important;
  font-weight: bolder;
  color: white;
}

.bg-red {
  background: linear-gradient(135deg, rgba(228, 66, 45, 1) 0%, rgba(229, 86, 86, 1) 100%);
}

.bg-dark-green {
  background: linear-gradient(135deg, rgba(75, 161, 87, 1) 0%, rgba(79, 115, 70, 1) 100%);
}

.bg-lgreen {
  background: linear-gradient(135deg, rgba(146, 216, 25, 1) 0%, rgba(174, 223, 84, 1) 100%);
}

.bg-blue {
  background: linear-gradient(to bottom, rgba(70, 108, 179, 1) 0%, rgba(46, 67, 109, 1) 100%);
}

.border {
  border-radius: 10px;
}

.font-white {
  color: white !important;
}

.remove-space {
  margin-bottom: -6px;
}

.btn-green {
  padding: 5px;
  background: #7ae500;
  width: 120px;
  height: 40px;
  color: white;
}

.btn-dgreen {
  background-color: #28a745;
  border-color: #28a745;
  color: white;

  :hover {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
  }
}

.bg-golden {
  background-color: #dd9c02 !important;
  color: #fff;
}

.position-end {
  float: right;
}

.top-20 {
  margin-top: 20px;
}

.color-red {
  color: #de4436;
}

.options-space {
  margin-right: 10px;
}

.cursor {
  cursor: pointer;
}

.em-text {
  color: #466cb3;
}

.form-height {
  overflow: auto;
  height: 85%;
  overflow-x: hidden;
}

.middle-v {
  position: relative;
  margin: 0 auto;
  align-items: center;
  align-content: center;
}
